import { useEffect } from "react";
import { Network } from "@capacitor/network";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faArrowsRotate,
	faWifi,
	faWifiSlash,
} from "@fortawesome/pro-regular-svg-icons";
import { Button, notification } from "antd";

export default function useNetworkStatusChange() {
	return useEffect(() => {
		Network.addListener("networkStatusChange", (res) => {
			let description = "";
			let class_status = "success-color";
			let icon = faWifi;

			if (res.connected) {
				description = (
					<>
						<span>Your internet connection was restored.</span>
						<Button
							type="dashed"
							icon={<FontAwesomeIcon icon={faArrowsRotate} />}
							onClick={() => {
								window.location.reload();
							}}
						>
							Refresh
						</Button>
					</>
				);
				class_status = "success-color";
				icon = faWifi;
			} else {
				description = "You are currently offline.";
				class_status = "";
				icon = faWifiSlash;
			}

			notification.info({
				message: "Internet Connection",
				description,
				placement: "bottomLeft",
				icon: <FontAwesomeIcon icon={icon} className={class_status} />,
				duration: 10,
			});
		});

		return () => {
			Network.removeAllListeners();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
}
