import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Row, Col, Carousel, Badge, Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/pro-solid-svg-icons";

import { GET } from "../../../providers/useAxiosQuery";
import { userData } from "../../../providers/companyInfo";
import {
	checkPermissions,
	deleteFile,
	readFile,
	requestPublicStoragePermissions,
	setFileStorage,
} from "../../../providers/useCapacitorStorage";
import carouselImg from "../../../assets/img/survey.png";
import SurveyList from "./SurveyList";

export default function PageDashboard() {
	const navigate = useNavigate();

	requestPublicStoragePermissions();

	const [formList, setFormList] = useState([]);
	const [surveyPendingList, setSurveyPendingList] = useState([]);

	GET(
		`api/mobile_form?profile_id=${userData().profile_id}&from=SurveyMobile`,
		"form_list",
		(res) => {
			// console.log("mobile_student_form res", res);
			if (res && res.data) {
				let data = res.data;

				let datasync = document.getElementById("datasync");
				if (datasync) {
					datasync.classList.remove("hide");
					console.log("datasync");
				}

				// let dataSurveyFiltered = data.filter(
				// 	(f) => f.form_question_categories.length > 0 && f.subjects.length > 0
				// );
				console.log("dataSurveyFiltered: ", data);
				setFormList(data);

				checkPermissions().then((res1) => {
					if (res1) {
						if (res1.publicStorage === "granted") {
							setFileStorage("dsac_fsuu_evaluation", "form_list", data).then(
								(res2) => {
									if (!res2 && !res2.uri) {
										deleteFile("dsac_fsuu_evaluation/form_list.txt").then(
											(res3) => {
												if (!res3) {
													setFileStorage(
														"dsac_fsuu_evaluation",
														"form_list",
														data
													).then((res4) => {
														if (res2 && res2.uri) {
															setTimeout(() => {
																if (datasync) {
																	datasync.classList.add("hide");
																}
															}, 1000);
														}
													});
												}
											}
										);
									} else {
										setTimeout(() => {
											if (datasync) {
												datasync.classList.add("hide");
											}
										}, 1000);
									}
								}
							);
						}
					}
				});
			}
		},
		false
	);

	useEffect(() => {
		checkPermissions().then((res1) => {
			if (res1) {
				if (res1.publicStorage === "granted") {
					readFile("dsac_fsuu_evaluation/form_list.txt").then((res2) => {
						if (res2 && res2.data) {
							res2 = JSON.parse(res2.data);
							setFormList(res2);
						}
					});

					readFile("dsac_fsuu_evaluation/survey_pending_list.txt").then(
						(res2) => {
							if (res2 && res2.data) {
								res2 = JSON.parse(res2.data);
								setSurveyPendingList(res2);
							}
						}
					);
				}
			}
		});

		return () => {};
	}, []);

	return (
		<Row gutter={[12, 12]} justify="center">
			<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
				<Carousel autoplay className="intro">
					<div className="carousel-item-home">
						<img src={carouselImg} alt="" />
					</div>
				</Carousel>
			</Col>

			<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
				<Card
					className="survey-list-wrapper"
					title={
						<>
							<div className="title">SURVEY LIST</div>
							<div className="action cursor-pointer">
								{surveyPendingList.length > 0 ? (
									<Badge
										count={surveyPendingList ? surveyPendingList.length : 0}
										overflowCount={99}
										onClick={() => navigate("/survey/pending")}
									>
										<FontAwesomeIcon
											icon={faSquare}
											style={{
												color: "#2d60e2",
											}}
										/>
									</Badge>
								) : null}
							</div>
						</>
					}
					extra={
						<Button
							type="link"
							className="w-auto h-auto p-0"
							onClick={() => {
								deleteFile("dsac_fsuu_evaluation/form_list.txt")
									.then((res) => {
										if (res) {
											console.log("deleteFile res", res);
										}
									})
									.then(() => {
										deleteFile("dsac_fsuu_evaluation/survey_pending_list.txt");
										window.location.reload();
									});
							}}
						>
							Reset Data
						</Button>
					}
					bordered={false}
				>
					<SurveyList formList={formList} />
				</Card>
			</Col>
		</Row>
	);
}
